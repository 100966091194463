import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import DevConsole from '~/components/atoms/DevConsole';
import * as serviceWorker from './serviceWorker';
import LineAxios from '~/services/LineAxios';
import * as Sentry from '@sentry/browser';
import VConsole from 'vconsole';
import "core-js/stable";
import "regenerator-runtime/runtime";
// import DevConsole from '~/components/atoms/DevConsole';

// import FastClick from 'fastclick';

if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  // whyDidYouRender(React, { trackAllPureComponents: true });
  process.env.REACT_APP_WHY_DID_YOU_RENDER === 'true' && whyDidYouRender(React);
}

window.onload = () => {
  // FastClick.attach(document.body);
  let lastTouchEnd = 0;
  document.addEventListener(
    'touchend',
    event => {
      const now = new Date().getTime();
      if (now - lastTouchEnd <= 500) {
        process.env !== 'production' && console.log('Too frequently tap');
        // event.preventDefault();
      }
      lastTouchEnd = now;
    },
    false
  );

  document.addEventListener(
    'touchstart',
    event => {
      if (event.touches.length > 1) {
        const el = event.target;
        const ignore = el.getAttribute('data-zoom-ignore') === 'true';
        if (!ignore) {
          event.preventDefault();
        }
      }
    },
    { passive: false }
  );
};

const initializeLiff = myLiffId => {
  window.TPDirect.setupSDK(
    15240,
    'app_QW5mwl9stqaIZIa36xf6ZdwJPT818F3D44gbcWnkZDiWx6i4p1ptIRKPwjGE',
    process.env.NODE_ENV === 'production' ? 'production' : 'sandbox'
  );
  window.liff &&
    window.liff
      .init({
        liffId: myLiffId,
      })
      .then(() => {
        // start to use LIFF's api
        initialize();
      })
      .catch(error => {
        console.error(error);
      });
};

const initialize = () => {
  if (process.env.NODE_ENV !== 'production') {
    initializeDevConsole();
  }
  initializeApp();
  Sentry.init({ dsn: 'https://234b7ddb6ceb459d9d7c75cbb825b067@sentry.io/5125746' });
};

const initializeDevConsole = () => {
  if (process.env.REACT_APP_SHOW_CONSOLE === 'true') {
    var vConsole = new VConsole();
  }
  // ReactDOM.render(
  //   <DevConsole
  //     onMounted={() => {
  //       initializeApp();
  //     }}
  //   />,
  //   document.body.appendChild(document.createElement('div'))
  // );
};

const initializeApp = async () => {
  const lang = window.liff.getLanguage();
  LineAxios.setInstancesLocale(lang);
  ReactDOM.render(<App />, document.getElementById('root'));
};

// const initializeGA = () => {
//   window.dataLayer = window.dataLayer || [];
//   function gtag() {
//     window.dataLayer.push(arguments);
//   }

//   gtag('js', new Date());

//   if (process.env.NODE_ENV !== 'production') {
//     gtag('config', 'UA-164159000-2');
//   } else {
//     gtag('config', 'UA-164159000-1');
//   }
// };

initializeLiff(process.env.REACT_APP_LIFF_ID);

// initializeGA();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

/* 
v=2&
tid=G-LESFMY9T6G&
gtm=2oe4t0&
_p=1617291417&
sr=414x896&
ul=zh-tw&
_fid=f53t-w9sYWprholm9cs0Z7&
cid=1453531671.1539008350&
_s=1&
dl=https%3A%2F%2Fvenus-qat.wemoscooter.com%2F%3Futm_medium%3Doa%26utm_source%3Dline%26utm_campaign%3Dwebsite&
dr=https%3A%2F%2Fvenus-qat.wemoscooter.com%2F%3Fliff.state%3D%253Futm_medium%253Doa%2526utm_source%253Dline%2526utm_campaign%253Dwebsite&
dt=WeMo%20Scooter&
sid=1588823623&
sct=51&
seg=1&
en=page_view&
ep.origin=firebase HTTP/1.1


 11:58
 v=2&
 tid=G-LESFMY9T6G&
 gtm=2oe4t0&
 _p=1652382878&
 sr=414x896&
 ul=zh-tw&
 _fid=fk1FMAHa9CCE-nenaFT0Oz&
 cid=272680645.1588740310&
 _s=1&
 dl=https%3A%2F%2Fvenus-qat.wemoscooter.com%2Ferrors%2Funauthorized&
 dr=https%3A%2F%2Fvenus-qat.wemoscooter.com%2F%3Fliff.state%3D%252F%253Futm_medium%253Doa%2526utm_source%253Dline%2526utm_campaign%253Dwebsite&
 dt=WeMo%20Scooter&
 sid=1588823833&
 sct=4&
 seg=0&
 en=page_view&
 _ss=1&
 ep.origin=firebase HTTP/1.1

*/
